import { Close } from "@mui/icons-material";
import {
  Box,
  Container,
  Grid,
  Hidden,
  Typography,
  Stack,
  Button,
  Drawer,
  IconButton,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import Navbar from "../../components/Navbar";
import { asset, FilterProvider } from "./context/FilterContext";
import Sidebar from "../../components/Sidebar";
// import { bgColor, pages } from "../utils/utility";
import { FilterGroup } from "./components/FilterGroup";
import { FilterRows } from "./components/FilterRows";
import { GalleryGrid } from "./components/GalleryGrid";
import { GalleryHeader } from "./components/GalleryHeader";
import { getRarityScore } from "../../utils/nftRanking";
// import assets from "./result.json";

const formatedAssets = (assets) =>
  assets.map((asset) => {
    const { name, attributes } = asset;
    const formmatedAttributes = _.reduce(
      attributes,
      (all, { value, trait_type }) => ({ ...all, [trait_type]: value }),
      {}
    );
    return {
      ...asset,
      name: name.split("#")[0],
      serial: name.split("#")[1],
      attributes: formmatedAttributes,
    };
  });

export const GalleryPage = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const [isMobileDrawerOpen, setisMobileDrawerOpen] = useState(false);
  const [formattedAssets, setFormattedAssets] = useState<asset[]>(
    [] as asset[]
  );
  const prepareAssets = useCallback(async () => {
    const resp = await fetch("/assets/result.json");
    const data = await resp.json();
    const formattedData = formatedAssets(getRarityScore(data));
    setFormattedAssets(formattedData);
  }, []);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    prepareAssets();
  }, []);

  if (!formattedAssets || !formattedAssets.length) {
    return (
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          zIndex: 99,
          top: 0,
          left: 0,
          overflow: "hidden",
          minHeight: "100vh",
          display: "flex",
          color: "#000",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <CircularProgress color="inherit" thickness={4} size={100} />
      </Box>
    );
  }

  return (
    <FilterProvider assets={formattedAssets}>
      <Box
        sx={{
          width: "100% !important",
          overflow: "hidden",
          pb: { md: 0 },
          background: "#FC9E9E",
          minHeight: "100vh",
          fontFamily: `Helvetica Neue LT Std`,
          textTransform: "uppercase",
          fontWeight: "bold",
          color: `#000`,
        }}
      >
        <Hidden mdDown>
          <Box sx={{ position: "absolute", top: 32, right: 120, zIndex: 9999 }}>
            <Navbar
              onMobileClose={() => setIsSidebarMobileOpen(false)}
              openMobile={isSidebarMobileOpen}
              onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)}
              isProve
            />
          </Box>
        </Hidden>
        <Sidebar
          onMobileClose={() => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
          onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)}
          isProve
        />
        <Container
          sx={{
            position: "relative",
            mt: { xs: 10, md: 16 },
            color: `#000`,
          }}
        >
          <Grid container spacing={2}>
            {/* <Grid item>
              <Button
                onClick={() => {
                  const csvString = [
                    [
                      "id",
                      // "attributes",
                      // "description",
                      "dna",
                      // "external_url",
                      // "image",
                      // "name",
                      // "ranking",
                      "rarityScore",
                    ],
                    ...formattedAssets.map((item) => [
                      item.serial,
                      item.dna,
                      item.rarityScore,
                    ]),
                  ]
                    .map((e) => e.join(","))
                    .join("\n");
                  console.log(csvString);

                  let encodedUri = encodeURI(
                    "data:text/csv;charset=utf-8," + csvString
                  );
                  window.open(encodedUri);
                }}
                sx={{ backgroundColor: "red", color: "white" }}
                fullWidth
              >
                onetime Download csv
              </Button>
            </Grid> */}
            <Hidden mdDown>
              <Grid item md={4}>
                <Typography
                  variant="h4"
                  textTransform={`uppercase`}
                  fontFamily={`inherit`}
                >
                  Filter
                </Typography>
                <FilterGroup />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={8}>
              <GalleryHeader
                onClickFilter={() => setisMobileDrawerOpen(true)}
              />
              <FilterRows />
              <Typography
                variant="h6"
                textTransform={`uppercase`}
                fontFamily={`inherit`}
                mt={2}
              >
                For rarity rankings, please refer to{" "}
                <a
                  href="https://rarity.tools/official-moar-by-joan-cornella"
                  target="_blank"
                >
                  rarity.tools
                </a>
                .
              </Typography>
              <Box mt={2}>
                <GalleryGrid />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Hidden mdUp>
          <Drawer
            anchor={`right`}
            open={isMobileDrawerOpen}
            onClose={() => setisMobileDrawerOpen(false)}
            PaperProps={{
              sx: {
                width: `100%`,
              },
            }}
            sx={{
              zIndex: 9999999,
            }}
            variant="temporary"
          >
            <Stack direction={`row`} px={2} py={1}>
              <Typography
                variant="h4"
                textTransform={`uppercase`}
                fontFamily={`Helvetica Neue LT Std`}
                fontWeight={`bold`}
              >
                Filter
              </Typography>
              <Box flex={1} />
              <IconButton onClick={() => setisMobileDrawerOpen(false)}>
                <Close />
              </IconButton>
            </Stack>
            <Divider />
            <FilterGroup />
          </Drawer>
        </Hidden>
      </Box>
    </FilterProvider>
  );
};
