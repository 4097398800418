import { Box, Skeleton } from "@mui/material";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { useState } from "react";

export const ImageBox = ({
  src,
  onClick,
}: {
  src: string;
  onClick: () => void;
}) => {
  const [imageLoading, setImageLoading] = useState(true);

  const imageLoaded = () => {
    setImageLoading(false);
  };

  return (
    <Box sx={{ position: "relative", minHeight: `170px` }}>
      <LazyMotion features={domAnimation}>
        {imageLoading && (
          <Skeleton
            variant="rectangular"
            width={`95%`}
            height={`95%`}
            sx={{
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 1,
              bgcolor: "grey.400",
            }}
          />
        )}
        <m.img
          src={src}
          alt=""
          width="95%"
          style={{ margin: "8px auto" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: imageLoading ? 0 : 1 }}
          onLoad={imageLoaded}
          whileHover={{ scale: 1.05 }}
          onClick={onClick}
        />
      </LazyMotion>
    </Box>
  );
};
