import { Box, Chip, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import useFilter from "../hooks/useFilter";

export const FilterRows = () => {
  const { activeFilters, setFilter } = useFilter();

  const filterChips = useMemo(() => {
    return Object.keys(activeFilters).reduce((filterArr, currentKey) => {
      const filters = activeFilters[currentKey].map((label) => ({
        key: currentKey,
        value: label,
      }));
      return filterArr.concat(filters);
    }, []);
  }, [activeFilters]);

  const handleClick = (key, value) => {
    // setFilter;
    const filtersArr = [...activeFilters[key]];
    filtersArr.splice(filtersArr.indexOf(value), 1);
    setFilter({ [key]: filtersArr });
  };

  return (
    <Stack direction={`row`} spacing={2} alignItems={`center`}>
      <Stack direction={`row`} alignItems={`center`} spacing={2}>
        <Typography>Filter</Typography>
        <Box
          sx={{
            background: "#000",
            color: "#fff",
            width: 24,
            ml: 2,
            textAlign: "center",
            borderRadius: 1,
          }}
        >
          <Typography>{filterChips.length}</Typography>
        </Box>
      </Stack>
      <Stack
        direction={`row`}
        spacing={2}
        sx={{
          overflowX: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        {filterChips.map((filter) => {
          const { key, value } = filter;
          return (
            <Chip
              key={`${key}: ${value}`}
              label={`${key}: ${value}`}
              onClick={() => handleClick(key, value)}
              onDelete={() => handleClick(key, value)}
              color={`default`}
              sx={{
                textTransform: "uppercase",
                // backgroundColor: "#000",
                // color: "#fff",
              }}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
