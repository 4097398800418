import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ReactElement, useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useFilter from "../hooks/useFilter";

export const Filter = ({
  icon,
  filterName,
  items,
  openInBegin = false,
}: {
  icon: string | ReactElement;
  filterName: string;
  items: { label: string; count: number }[];
  openInBegin?: boolean;
}) => {
  const [open, setOpen] = useState(openInBegin);
  const [itemFilter, setItemFilter] = useState("");

  const { activeFilters, setFilter } = useFilter();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleOnCheck = (e) => {
    const { name, checked } = e.target;
    const filtersArr = [...activeFilters[filterName]];
    if (checked) {
      filtersArr.push(name);
    } else {
      filtersArr.splice(filtersArr.indexOf(name), 1);
    }
    setFilter({ [filterName]: filtersArr });
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        {icon && (
          <ListItemIcon sx={{ color: { xs: "#000", md: "#000" } }}>
            {typeof icon === "string" ? (
              <img src={icon} alt="" width={`100%`} />
            ) : (
              icon
            )}
          </ListItemIcon>
        )}
        <ListItemText disableTypography>
          <Stack direction="row">
            <Typography fontFamily={"Gotham"} fontWeight={`bold`}>
              {filterName}
            </Typography>
            {activeFilters[filterName].length > 0 && (
              <Box
                sx={{
                  background: "#000",
                  color: "#fff",
                  width: 24,
                  ml: 2,
                  textAlign: "center",
                  borderRadius: 1,
                }}
              >
                <Typography>{activeFilters[filterName].length}</Typography>
              </Box>
            )}
          </Stack>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box px={2}>
          <TextField
            id="search"
            value={itemFilter}
            fullWidth
            onChange={(e) => setItemFilter(e.target.value)}
            placeholder="Search..."
            size="small"
            sx={{
              input: {
                color: { xs: "#000", md: "#000" },
              },
            }}
          />
        </Box>
        <List
          component="div"
          disablePadding
          sx={{
            maxHeight: 300,
            overflowY: "scroll",
          }}
        >
          {items
            .filter((item) => item.label.includes(itemFilter))
            .map((item) => (
              <ListItemButton sx={{ pl: 2 }} key={item.label}>
                {
                  <FormGroup sx={{ width: `100%` }}>
                    <FormControlLabel
                      name={item.label}
                      control={
                        <Checkbox
                          size="small"
                          checked={activeFilters[filterName].includes(
                            item.label
                          )}
                          onChange={handleOnCheck}
                          sx={{
                            color: { xs: "#000", md: "#000" },
                            "&.Mui-checked": {
                              color: { xs: "#000", md: "#000" },
                            },
                          }}
                        />
                      }
                      disableTypography
                      label={
                        <Typography fontFamily={"Gotham"} fontWeight={`bold`}>
                          {`${item.label} (${item.count})`}
                        </Typography>
                      }
                    />
                  </FormGroup>
                }
              </ListItemButton>
            ))}
        </List>
      </Collapse>
    </>
  );
};
