import { createContext, useEffect, useReducer } from "react";
import type { FC, ReactNode } from "react";
import _ from "lodash";
import { RankingConfig } from "../../../utils/nftRanking";

type activeFilter = Record<string, string[]>;
export class Attribute {
  value?: string;
  rarity?: string;
  trait_type?: string;
  order?: number;
  category?: string;
  group?: string;
}

export interface asset {
  name: string;
  serial: string;
  image: string;
  description: string;
  external_url: string;
  attributes?: Attribute[];
  // attributes: Record<string, string>;
  rarityScore?: number;
  ranking?: RankingConfig;
  dna?: string;
}
interface filter {
  name: string;
  items: { label: string; count: number }[];
}
interface State {
  activeFilters: activeFilter;
  filters: filter[];
  activeData: asset[];
  originalData: asset[];
}

interface FilterContextValue extends State {
  setFilter: (filter: activeFilter) => void;
  clearFilters: () => void;
}

interface FilterProviderProps {
  assets: asset[];
  children: ReactNode;
}

const initialState = {
  activeFilters: {},
  filters: [],
  activeData: [],
  originalData: [],
};

const setupData = (assets: asset[]) => {
  const filters = assets.reduce((allFilters, currentImage) => {
    const { attributes } = currentImage;
    const existingFilter = [...allFilters];
    Object.keys(attributes).forEach((attrKey) => {
      const attrValue = attributes[attrKey];
      let filterObj = existingFilter.find((f) => f.name === attrKey);
      if (!filterObj) {
        filterObj = {
          name: attrKey,
          items: [],
        };
        existingFilter.push(filterObj);
      }
      let filterItems = filterObj.items.find((i) => i.label === attrValue);
      if (!filterItems) {
        filterItems = {
          label: attrValue,
          count: 0,
        };
        filterObj.items.push(filterItems);
      }
      filterItems.count += 1;
    });
    return existingFilter;
  }, []);

  const filterState = filters.reduce((allState, currentFilter) => {
    return {
      ...allState,
      [currentFilter.name]: [],
    };
  }, {});

  return {
    filters,
    filterState,
  };
};

const filterItems = ({
  activeFilters,
  allItems,
}: {
  activeFilters: activeFilter;
  allItems: asset[];
}) => {
  const activeFilterArr = _.filter(activeFilters, (val) => val.length > 0);
  if (!activeFilterArr.length) return allItems;
  return _.filter(allItems, ({ serial, attributes }) => {
    const existed = Object.keys(activeFilters).filter((filterKey) => {
      const filters = activeFilters[filterKey];
      return filters.includes(
        filterKey === "serial" ? serial : attributes[filterKey]
      );
    });
    return existed.length === activeFilterArr.length;
  });
};

const handlers: Record<string, (state: State, action: any) => State> = {
  SET_FILTER: (state: State, action) => {
    const { filter } = action.payload;
    const activeFilters = {
      ...state.activeFilters,
      ...filter,
    };
    const activeData = filterItems({
      activeFilters,
      allItems: state.originalData,
    });
    return {
      ...state,
      activeFilters,
      activeData,
    };
  },
  CLEAR_FILTERS: (state: State, action) => {
    const { filterState } = setupData(state.originalData);
    return {
      ...state,
      activeFilters: filterState,
      activeData: state.originalData,
    };
  },
  // SAMPLE_SETUP: (state: State, action) => {
  //   const { assets } = action.payload;
  //   const { filters, filterState } = setupData(assets);
  //   return {
  //     ...state,
  //     filters,
  //     activeFilters: filterState,
  //   };
  // },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const FilterContext = createContext<FilterContextValue>({
  ...initialState,
  setFilter: (filter) => {},
  clearFilters: () => {},
  // logout: () => Promise.resolve(),
});

export const FilterProvider: FC<FilterProviderProps> = (props) => {
  const { children, assets } = props;
  const { filters, filterState } = setupData(assets);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    activeData: assets,
    originalData: assets,
    filters,
    activeFilters: filterState,
  });

  // useEffect(() => {
  //   if (assets.length) {
  //     dispatch({
  //       type: "SAMPLE_SETUP",
  //       payload: {
  //         assets,
  //       },
  //     });
  //   }
  // }, [assets]);

  const setFilter = (filter) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        filter,
      },
    });
  };

  const clearFilters = (filterKey) => {
    dispatch({
      type: "CLEAR_FILTERS",
    });
  };

  return (
    <FilterContext.Provider value={{ ...state, setFilter, clearFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContext;
