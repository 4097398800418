import { Divider, List } from "@mui/material";
import { Filter2Outlined } from "@mui/icons-material";
import { Filter } from "./Filter";
import { Fragment } from "react";
import useFilter from "../hooks/useFilter";
import { SearchBySerial } from "./SearchBySerial";

export const FilterGroup = () => {
  const { filters } = useFilter();
  return (
    <List
      sx={{
        height: {
          // xs: window.innerHeight - 180,
          md: window.innerHeight - 160,
        },
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      <SearchBySerial />
      {filters.map((filter, index) => {
        const icon = null;
        // const icon = <Filter2Outlined />;
        return (
          <Fragment key={filter.name}>
            <Filter icon={icon} filterName={filter.name} items={filter.items} />
            {index !== filters.length - 1 && (
              <Divider sx={{ borderColor: { md: "#000" } }} />
            )}
          </Fragment>
        );
      })}
    </List>
  );
};
