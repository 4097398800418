import { FilterAlt } from "@mui/icons-material";
import { Box, Button, Hidden, Stack, Typography } from "@mui/material";
import useFilter from "../hooks/useFilter";
export const GalleryHeader = ({
  onClickFilter,
}: {
  onClickFilter: () => void;
}) => {
  const { activeData } = useFilter();

  return (
    <Stack direction={`row`}>
      <Stack direction={`row`} alignItems={`center`} spacing={2}>
        <Typography
          variant="h4"
          textTransform={`uppercase`}
          fontFamily={`inherit`}
        >
          Items
        </Typography>
        <Typography>{activeData.length}</Typography>
      </Stack>
      <Box flex={1} />
      <Stack direction={`row`}>
        <Hidden mdUp>
          <Button
            startIcon={<FilterAlt />}
            // color={`secondary`}
            onClick={onClickFilter}
            sx={{
              color: `#000`,
              "&:hover, &:active, &:focus": {
                color: `#000`,
              },
            }}
          >
            Filter
          </Button>
        </Hidden>
      </Stack>
    </Stack>
  );
};
