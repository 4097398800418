import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { ImageBox } from "./ImageBox";
import useFilter from "../hooks/useFilter";
import OpenSeaLogo from "../../../assets/openSeaLogo.svg";
import { asset } from "../context/FilterContext";

const getAssetImageUrl = (image) => {
  const urlSrc = image ? image.split("ipfs://")[1] : "";
  return urlSrc
    ? `https://gateway.thefwenclub.com/ipfs/${urlSrc}?width=512&quality=75`
    : "";
};

export const GalleryGrid = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { activeData, activeFilters, clearFilters } = useFilter();
  let sliceNum = isMobile ? 24 : 48;
  const [items, setItems] = useState(activeData.slice(0, sliceNum));
  const [selected, setSeleted] = useState(null as asset);

  useEffect(() => {
    setItems(activeData.slice(0, sliceNum));
  }, [activeData]);

  const fetchMoreData = () => {
    const numToSlice =
      items.length + sliceNum <= activeData.length
        ? items.length + sliceNum
        : activeData.length;
    setItems(activeData.slice(0, numToSlice));
  };

  const renderDialog = () => {
    return (
      <Dialog
        fullScreen={false}
        open={!!selected}
        onClose={() => setSeleted(null)}
        maxWidth={`md`}
        sx={{
          zIndex: 9999999,
        }}
      >
        {/* <DialogTitle id="responsive-dialog-title">
          TERMS & CONDITIONS
        </DialogTitle> */}
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <img
                src={getAssetImageUrl(selected?.image)}
                alt=""
                width={`100%`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DialogContentText fontFamily={"inherit"} fontWeight={`bold`}>
                {selected?.name}
              </DialogContentText>
              <Stack direction={`row`} alignItems={`center`}>
                <DialogTitle
                  sx={{ px: 0, fontFamily: "Gotham", fontWeight: "bold" }}
                >
                  #{selected?.serial}
                </DialogTitle>
                <Box flex={1} />
                <IconButton
                  sx={{ width: 48, height: 48 }}
                  href={`https://opensea.io/assets/0xeb3a9a839dfeeaf71db1b4ed6a8ae0ccb171b227/${selected?.serial}`}
                  target={`_blank`}
                >
                  <img src={OpenSeaLogo} alt="" width={`100%`} />
                </IconButton>
              </Stack>
              <Grid container spacing={1}>
                {_.sortBy(Object.keys(selected?.attributes || {})).map(
                  (key) => {
                    const attribute = selected.attributes[key];
                    return (
                      <Grid item xs={6} key={`${selected?.name}${key}`}>
                        <Box
                          sx={{
                            background: "#f6f6f6",
                            padding: "2px 8px",
                            borderRadius: 2,
                          }}
                        >
                          <Typography variant="caption">{key}</Typography>
                          <Typography variant="body2" fontWeight={`bold`}>
                            {attribute}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const containerHeight = isMobile
    ? window.innerHeight - 180
    : window.innerHeight - 120 - 100;

  const threshold = isMobile ? -50 : -100;

  return (
    <Box
      sx={{
        ".infinite-scroll-component::-webkit-scrollbar": {
          display: "none",
        },
        ".infinite-scroll-component": {
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        },
      }}
    >
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        scrollThreshold={threshold}
        hasMore={items.length < activeData.length}
        loader={<h4>Loading...</h4>}
        height={containerHeight}
        endMessage={
          <Box sx={{ textAlign: "center" }}>
            {_.flatMap(activeFilters).length > 0 && activeData.length === 0 && (
              <>
                <p style={{ textAlign: "center" }}>
                  <b>No NFT matched your filter parameters</b>
                </p>
                <Button
                  variant={`contained`}
                  // color={`secondary`}
                  sx={{
                    background: `#000`,
                    "&:hover, &:active, &:focus": {
                      background: `#000`,
                    },
                  }}
                  onClick={clearFilters}
                >
                  Clear Filters
                </Button>
              </>
            )}
          </Box>
        }
      >
        <Grid container spacing={2}>
          {items.map((image) => {
            return (
              <Grid
                item
                xs={6}
                md={3}
                key={image.serial}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <ImageBox
                  src={getAssetImageUrl(image.image)}
                  onClick={() => setSeleted(image)}
                />
                <Stack textAlign={`center`}>
                  <Typography variant="caption" fontFamily={`inherit`} sx={{}}>
                    {image.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    fontFamily={"Gotham"}
                    fontWeight={`bold`}
                  >
                    ID {image.serial}
                  </Typography>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
      {renderDialog()}
    </Box>
  );
};
