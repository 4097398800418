import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import useFilter from "../hooks/useFilter";

export const SearchBySerial = () => {
  const { activeFilters, setFilter } = useFilter();

  const handleOnChange = (e) => {
    setFilter({ serial: e.target.value ? [e.target.value] : [] });
  };

  return (
    <TextField
      id="serial"
      value={(activeFilters?.serial || [])[0] || ""}
      fullWidth
      onChange={handleOnChange}
      placeholder="Search by serial..."
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{ color: { xs: "#000", md: "#000" } }}
          >
            <Search />
          </InputAdornment>
        ),
      }}
      sx={{
        input: {
          color: { xs: "#000", md: "#000" },
        },
        px: { xs: 2, md: 0 },
      }}
    />
  );
};
